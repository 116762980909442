/**
 * VideoJSEdit block.
 * @module components/manage/Blocks/VideoJS/VideoJSEdit
 */

import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { Message } from 'semantic-ui-react';
import cx from 'classnames';
import config from '@plone/volto/registry';
import { MEDIA_PAGE_WOWZA_ROUTE } from '@package/constants';

import { SidebarPortal } from '@plone/volto/components';
import videoBlockSVG from './videojs-icon.svg';
import Body from './Body';
import { withBlockExtensions } from '@plone/volto/helpers';
import { compose } from 'redux';
import VideoJSSidebar from './VideoJSSidebar';
import { v4 as uuid } from 'uuid';
import mime from 'mime-types';
import { Api, flattenToAppURL } from '@plone/volto/helpers';

/**
 * Edit videoJS block.
 * @component VideoJSEdit
 */
const VideoJSEdit = (props) => {
  const { block, data, onChangeBlock, properties } = props;
  const [key, setKey] = React.useState('');
  const [showPlayer, setShowPlayer] = useState(false);

  const { token } = useSelector((state) => state.userSession);
  const api = new Api();

  React.useEffect(() => {
    let controller = new AbortController();

    if (props['type'] === 'mediapagevideojs') {
      (async () => {
        const tempData = {};

        const items = properties.items;
        if (items.length) {
          const subtitles = [];
          for (const item of items) {
            if (item['@type'] === 'subtitle') {
              // Only take subtitles marked as completed
              const workflow = await api.get(
                flattenToAppURL(item['@id']) + '/@workflow',
                {
                  headers: {
                    Authorization: `Bearer ${token}`,
                  },
                },
              );
              const state =
                workflow?.['chain']?.['media_output_workflow']?.['state']?.[
                  'id'
                ];
              if (state && state !== 'completed') {
                continue;
              }
              subtitles.push([
                {
                  '@id': item['url'],
                  title: item['title'],
                  language: item['title'],
                },
              ]);
            }
          }

          if (subtitles.length) {
            tempData.subtitles = subtitles;
          }
        }

        if (
          properties['s3_object_id'] !== null &&
          properties['s3_object_id'] !== ''
        ) {
          try {
            const resp = await fetch(
              `${config.settings.publicPath}/${MEDIA_PAGE_WOWZA_ROUTE}/${properties['UID']}/${properties['s3_object_id']}`,
              {
                signal: controller.signal,
              },
            );
            const jsonData = await resp.json();
            if (jsonData['url']) {
              tempData.sources = [
                {
                  src: jsonData['url'],
                  type: mime.lookup(jsonData['url']),
                },
              ];
              onChangeBlock(block, {
                ...data,
                options: {
                  ...data.options,
                  ...tempData,
                },
              });
              setShowPlayer(true);
            }
          } catch (e) {
            // eslint-disable-next-line no-console
            console.log(e);
          }
        }
      })();
    } else {
      setShowPlayer(true);
    }
    return () => {
      controller?.abort();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const refreshPlayer = () => {
    setKey(uuid());
  };

  return (
    <div
      className={cx(
        'block video align',
        {
          selected: props.selected,
          center: !Boolean(props.data.align),
        },
        props.data.align,
      )}
    >
      {showPlayer &&
      props.data.options?.sources?.length &&
      props.data.options?.sources[0].src ? (
        <Body {...props} keyValue={key} editMode={true} />
      ) : (
        <Message>
          <center>
            <img src={videoBlockSVG} alt="" />
          </center>
        </Message>
      )}
      <SidebarPortal selected={props.selected}>
        <VideoJSSidebar {...props} refreshPlayer={refreshPlayer} />
      </SidebarPortal>
    </div>
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
VideoJSEdit.propTypes = {
  selected: PropTypes.bool.isRequired,
  block: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  onChangeBlock: PropTypes.func.isRequired,
  onSelectBlock: PropTypes.func.isRequired,
  onDeleteBlock: PropTypes.func.isRequired,
  onFocusPreviousBlock: PropTypes.func.isRequired,
  onFocusNextBlock: PropTypes.func.isRequired,
  handleKeyDown: PropTypes.func.isRequired,
};

export default compose(injectIntl, withBlockExtensions)(VideoJSEdit);
