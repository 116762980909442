import React from 'react';
import { Button } from 'semantic-ui-react';

const Edit = (props) => {
  return (
    <div className="upload-video-button-container block">
      <Button color="blue">Upload video or audio</Button>
    </div>
  );
};

export default Edit;
