import { useEffect, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { Portal } from 'react-portal';
import { Button, Container, Segment } from 'semantic-ui-react';
import { FormattedMessage, defineMessages, useIntl } from 'react-intl';

import { Toolbar } from '@plone/volto/components';
import { Helmet } from '@plone/volto/helpers';
import { getContent, getQueryStringResults } from '@plone/volto/actions';
import { moveMediaPage } from '@package/actions/move/move';

const messages = defineMessages({
  move: {
    id: 'Move',
    defaultMessage: 'Move',
  },
  ok: {
    id: 'Ok',
    defaultMessage: 'Ok',
  },
  cancel: {
    id: 'Cancel',
    defaultMessage: 'Cancel',
  },
});

const Move = () => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const [isClient, setisClient] = useState(false);
  const { pathname } = useLocation();
  const history = useHistory();
  const content = useSelector((state) => state.content?.data, shallowEqual);

  const programs = useSelector((state) => state.querystringsearch?.items);
  const [selectedProgram, setSelectedProgram] = useState(null);
  const moveRequest = useSelector((state) => state?.move);

  useEffect(() => {
    setisClient(true);
  }, []);

  useEffect(() => {
    dispatch(getContent(pathname.replace(/\/move$/, '')));
  }, [dispatch, pathname]);

  useEffect(() => {
    if (!!moveRequest?.move?.result) {
      history.push({
        pathname: moveRequest.move.result,
        search: '',
      });
    }
  }, [history, moveRequest]);

  const onSubmit = (event) => {
    dispatch(moveMediaPage(content.UID, selectedProgram));
    event.preventDefault();
    return false;
  };

  const onCancel = (event) => {
    history.push({
      pathname: pathname.replace(/\/move$/, ''),
    });
    event.preventDefault();
    return false;
  };

  useEffect(() => {
    dispatch(
      getQueryStringResults('', {
        b_size: 1000,
        sort_on: 'modified',
        sort_order: 'descending',
        query: [
          {
            i: 'portal_type',
            o: 'plone.app.querystring.operation.selection.any',
            v: ['program'],
          },
        ],
      }),
    );
  }, [dispatch]);

  if (content) {
    return (
      <div id="page-move">
        <Helmet title={intl.formatMessage(messages.move)} />
        <Container>
          <form>
            <Segment.Group raised>
              <Segment className="primary">
                <FormattedMessage
                  id="Where would you like to move this item?"
                  defaultMessage="Where would you like to move this item?"
                />
              </Segment>
              <Segment attached>
                {programs.map((option) => (
                  <label key={option['@id']}>
                    <input
                      type="radio"
                      name="program"
                      title={option.title}
                      value={option['@id']}
                      onChange={(event) => {
                        setSelectedProgram(event.target.value);
                      }}
                      style={{ marginRight: '1ex' }}
                    />
                    {option.title}
                    <br />
                  </label>
                ))}
              </Segment>
              <Segment className="actions" clearing>
                <Button
                  basic
                  circular
                  primary
                  floated="right"
                  icon="arrow right"
                  aria-label={intl.formatMessage(messages.ok)}
                  title={intl.formatMessage(messages.ok)}
                  size="big"
                  disabled={!selectedProgram}
                  onClick={onSubmit}
                />
                <Button
                  basic
                  circular
                  secondary
                  icon="remove"
                  aria-label={intl.formatMessage(messages.cancel)}
                  title={intl.formatMessage(messages.cancel)}
                  floated="right"
                  size="big"
                  onClick={onCancel}
                />
              </Segment>
            </Segment.Group>
          </form>
        </Container>
        {isClient && (
          <Portal node={document.getElementById('toolbar')}>
            <Toolbar
              pathname={pathname}
              hideDefaultViewButtons
              inner={<span />}
            />
          </Portal>
        )}
      </div>
    );
  }
  return <div />;
};

export default Move;
