const getVar = (varName, defaultValue) => {
  var result = defaultValue;
  if (process.env?.[varName]) {
    result = process.env[varName];
  } else {
    if (typeof window !== 'undefined' && window.env?.[varName]) {
      result = window.env[varName];
    }
  }
  return result;
};
export { getVar };
