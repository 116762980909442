import {
  EPICS_DEFINITIONS as EPICS,
  EPICS_TASK_VIEWS as TASKS,
  EPICS_TOAST_PROPS as TOAST,
} from 'volto-epics-addon/constants';

export const EPIC_FILE_UPLOAD = 'file-upload';
export const EPIC_SUBTITLE_PROCESS = 'subtitle-process';
EPICS[EPIC_FILE_UPLOAD] = EPIC_FILE_UPLOAD;
EPICS[EPIC_SUBTITLE_PROCESS] = EPIC_SUBTITLE_PROCESS;

export const TASK_UPLOAD_PROMPT = 'file-upload-prompt';
TASKS[TASK_UPLOAD_PROMPT] = '/file-upload-prompt';
TOAST[TASK_UPLOAD_PROMPT] = {
  title: 'File was not uploaded.',
  showCancel: true,
};

export const TASK_UPLOAD_BASICINFO_PROMPT = 'basic-info-prompt';
TASKS[TASK_UPLOAD_BASICINFO_PROMPT] = '/basic-info-prompt';
TOAST[TASK_UPLOAD_BASICINFO_PROMPT] = {
  title: 'Basic info was not given.',
  showCancel: true,
};

export const TASK_UPLOAD_WAITFORJOBS_VIEW = 'wait-for-jobs-view';
TASKS[TASK_UPLOAD_WAITFORJOBS_VIEW] = '/wait-for-jobs';
TOAST[TASK_UPLOAD_WAITFORJOBS_VIEW] = {
  title: 'Waiting for jobs..',
  showCancel: true,
};

export const TASK_INTERMEDIATE_JOBS_VIEW = 'intermediate-jobs-view';
TASKS[TASK_INTERMEDIATE_JOBS_VIEW] = '/wait-for-intermediate-jobs';
TOAST[TASK_INTERMEDIATE_JOBS_VIEW] = {
  title: 'Waiting for jobs..',
  showCancel: true,
};

export const TASK_UPLOAD_DETAILS_PROMPT = 'details-prompt';
TASKS[TASK_UPLOAD_DETAILS_PROMPT] = '/details-prompt';
TOAST[TASK_UPLOAD_DETAILS_PROMPT] = {
  title: 'Details were not given.',
  showCancel: true,
};

export const MEDIA_PAGE_WOWZA_ROUTE = 'media-page-wowza';
export const MEDIA_PAGE_TRANSCRIPTION_ROUTE = 'media-page-transcription';
export const MEDIA_PAGE_PREVIEW_ROUTE = 'media-page-preview';
export const MEDIA_PAGE_SUBTITLES_ROUTE = 'media-page-subtitles';
export const MEDIA_PAGE_STORYBOARDS_ROUTE = 'media-page-storyboards';
