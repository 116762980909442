import React from 'react';
import { Container } from 'semantic-ui-react';
import { Icon } from '@plone/volto/components';
import Step1Icon from '@package/components/theme/FileUpload/icons/step1.svg';
import Step2Icon from '@package/components/theme/FileUpload/icons/step2.svg';
import Step3Icon from '@package/components/theme/FileUpload/icons/step3.svg';
import Step4Icon from '@package/components/theme/FileUpload/icons/step4.svg';
import OkIcon from '@package/components/theme/FileUpload/icons/ok.svg';

const UploadStepsContainer = ({ currentStep, totalSteps }) => {
  const getState = (loc) => {
    if (loc < currentStep) {
      return 'past';
    }
    if (loc > currentStep) {
      return 'future';
    }
    return 'current';
  };

  return (
    <Container className="upload-steps-container">
      <div className="upload-steps">
        <div className={'upload-steps-icon-main ' + getState(1)}>
          {getState(1) === 'past' ? (
            <Icon name={OkIcon} size="48px" />
          ) : (
            <Icon name={Step1Icon} size="48px" />
          )}
        </div>
        <div className={'upload-steps-icon-sub ' + getState(2)}></div>
        <div className={'upload-steps-icon-main ' + getState(2)}>
          {getState(2) === 'past' ? (
            <Icon name={OkIcon} size="48px" />
          ) : (
            <Icon name={Step2Icon} size="48px" />
          )}
        </div>
        <div className={'upload-steps-icon-sub ' + getState(3)}></div>
        <div className={'upload-steps-icon-main ' + getState(3)}>
          {getState(3) === 'past' ? (
            <Icon name={OkIcon} size="48px" />
          ) : (
            <Icon name={Step3Icon} size="48px" />
          )}
        </div>
        <div className={'upload-steps-icon-sub ' + getState(4)}></div>
        <div className={'upload-steps-icon-main ' + getState(4)}>
          {getState(4) === 'past' ? (
            <Icon name={OkIcon} size="48px" />
          ) : (
            <Icon name={Step4Icon} size="48px" />
          )}
        </div>
      </div>
    </Container>
  );
};

export default UploadStepsContainer;
