import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Button } from 'semantic-ui-react';
import { Api } from '@plone/volto/helpers';
import { flattenToAppURL } from '@plone/volto/helpers';
import { defineMessages, useIntl } from 'react-intl';
import Disclaimer from '@package/components/manage/Downloads/Disclaimer';

const messages = defineMessages({
  acknowledgementText: {
    id: 'downloadsBlockAcknowledgementText',
    defaultMessage:
      'I have understood the above and agree to handle the data with care.',
  },
});

function customSort(a, b) {
  // Function to check if the title is numeric (resolution)
  const isNumeric = (str) => /\d+p$/.test(str); // Ends with a number followed by 'p'

  const isNumericA = isNumeric(a.title);
  const isNumericB = isNumeric(b.title);

  // Group all non-numeric titles together and sort them alphabetically
  if (!isNumericA && isNumericB) return 1; // Non-numeric titles go after numeric ones
  if (isNumericA && !isNumericB) return -1; // Numeric titles come before non-numeric

  // If both are numeric, sort by the numeric value
  if (isNumericA && isNumericB) {
    const valueA = parseInt(a.title.match(/\d+/)[0], 10);
    const valueB = parseInt(b.title.match(/\d+/)[0], 10);
    return valueA - valueB;
  }

  // If both are non-numeric, sort alphabetically
  return a.title.localeCompare(b.title);
}

const View = (props) => {
  const [downloadItems, setDownloadItems] = useState([]);
  const [selectedItemId, setSelectedItemId] = useState(null);
  const [isAcknowledged, setIsAcknowledged] = useState(false);
  const content = useSelector((state) => state.content.data);
  const { token } = useSelector((state) => state.userSession);
  const api = new Api();
  const intl = useIntl();

  useEffect(() => {
    (async () => {
      const contentItems = content.items.filter((item) =>
        ['transcoding', 'subtitle'].includes(item['@type']),
      );

      const newDownloadItems = [];
      for (const item of contentItems) {
        // Get the full object
        const resp = await api.get(flattenToAppURL(item['@id']), {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        // Also get the workflow state
        const workflow = await api.get(
          flattenToAppURL(item['@id']) + '/@workflow',
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        );

        const state =
          workflow?.['chain']?.['media_output_workflow']?.['state']?.['id'];

        if (state !== 'completed') {
          continue;
        }

        // Add to the visible elements
        newDownloadItems.push({
          id: resp.id,
          title: resp.title,
          type: resp['@type'],
          s3_object_id:
            resp['@type'] === 'transcoding'
              ? resp.s3_object_id
              : resp.s3_subtitles_id,
          s3_object_path: content.UID,
        });

        // Add duplicate item for subtitles that allows downloading without timestamps
        if (item['@type'] === 'subtitle') {
          newDownloadItems.push({
            id: `${resp.id}-no-timestamps`,
            title: `${resp.title} (no timestamps)`,
            type: `subtitle`,
            s3_object_id: resp.s3_subtitles_id,
            s3_object_path: content.UID,
          });
        }
      }
      setDownloadItems(newDownloadItems);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const downloadButtonHandler = async (event) => {
    event.preventDefault();

    if (!selectedItemId || !isAcknowledged) {
      return;
    }

    const selectedItem = downloadItems.filter(
      (item) => item.id === selectedItemId,
    )[0];

    const url = flattenToAppURL(content['@id']) + '/@download-presigned';
    const resp = await api.post(url, {
      data: {
        s3_object_id: selectedItem.s3_object_id,
        s3_object_path: selectedItem.s3_object_path,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (!resp.presigned_url) {
      // eslint-disable-next-line no-console
      console.error(resp.error);
      return;
    }

    // For subtitles, fetch the content and optionally process before triggering download.
    if (selectedItem.type === 'subtitle') {
      try {
        const urlParts = new URL(resp.presigned_url);

        const response = await fetch(resp.presigned_url);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        let content = await response.text();
        let fileName = urlParts.pathname.split('/').pop();
        let type = 'text/vtt';

        if (selectedItemId.endsWith('-no-timestamps')) {
          const timestampRegex =
            /^(\d{2}:)?\d{2}:\d{2}\.\d{3} --> (\d{2}:)?\d{2}:\d{2}\.\d{3}$/;

          // Process the VTT content to remove timestamps
          content = content
            .split('\n')
            .filter((line, index) => index > 1 && !line.match(timestampRegex))
            .join('\n');

          fileName = fileName.replace('.vtt', '-no-timestamps.txt');
          type = 'text/plain';
        }

        const blob = new Blob([content], { type: type });

        // Create a temporary anchor element and trigger the download
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = fileName;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        // Release the object URL
        URL.revokeObjectURL(link.href);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(
          'Error fetching or processing the subtitle file: ',
          error,
        );
      }
      return;
    }

    // Otherwise redirect user to the presigned url.
    window.location.href = resp.presigned_url;
  };

  return (
    downloadItems.length > 0 && (
      <div className="downloads-container block">
        <div className="downloads-container-heading">
          <h2> Export </h2>
        </div>
        <div className="downloads-container-contents">
          <div className="downloads-container-text">
            <Disclaimer />
          </div>
          <div className="downloads-container-form">
            <form>
              {downloadItems.filter((item) => item['type'] === 'transcoding')
                .length > 0 && <h3> Media </h3>}
              {downloadItems
                .filter((item) => item['type'] === 'transcoding')
                .sort(customSort)
                .map((item, index) => (
                  <div key={item.id} style={{ marginBottom: '10px' }}>
                    <input
                      type="radio"
                      id={`downloadItem-${item.id}`}
                      name="downloadItem"
                      value={item.id}
                      checked={selectedItemId === item.id}
                      onChange={(e) => setSelectedItemId(e.target.value)}
                      style={{ marginRight: '5px' }}
                    />
                    <label htmlFor={`downloadItem-${item.id}`}>
                      {item.title}
                    </label>
                  </div>
                ))}
              {downloadItems.filter((item) => item['type'] === 'subtitle')
                .length > 0 && <h3> Subtitles </h3>}
              {downloadItems
                .filter((item) => item['type'] === 'subtitle')
                .sort(customSort)
                .map((item, index) => (
                  <div key={item.id} style={{ marginBottom: '10px' }}>
                    <input
                      type="radio"
                      id={`downloadItem-${item.id}`}
                      name="downloadItem"
                      value={item.id}
                      checked={selectedItemId === item.id}
                      onChange={(e) => setSelectedItemId(e.target.value)}
                      style={{ marginRight: '5px' }}
                    />
                    <label htmlFor={`downloadItem-${item.id}`}>
                      {item.title}
                    </label>
                  </div>
                ))}
              <label style={{ display: 'block', marginTop: '20px' }}>
                <input
                  type="checkbox"
                  style={{ marginRight: '10px' }}
                  checked={isAcknowledged}
                  onChange={() => setIsAcknowledged(!isAcknowledged)}
                />
                {intl.formatMessage(messages.acknowledgementText)}
              </label>
              <Button
                onClick={downloadButtonHandler}
                disabled={!isAcknowledged || !selectedItemId}
                style={{ marginTop: '10px' }}
              >
                Download
              </Button>
            </form>
          </div>
        </div>
      </div>
    )
  );
};

export default View;
