/**
 * RangeSliderWidget component.
 * @module components/manage/Blocks/VideoJS/RangeSliderWidget
 */

import React, { useCallback, useEffect, useState, useRef } from 'react';
import classnames from 'classnames';
import { FormattedMessage, injectIntl } from 'react-intl';
import { FormFieldWrapper } from '@plone/volto/components';
import moment from 'moment';
import { Segment } from 'semantic-ui-react';
import { Icon } from '@plone/volto/components';
import cutSVG from '@plone/volto/icons/cut.svg';

const RangeSliderWidget = (props) => {
  const { id, onChange, curMin, curMax, min, max } = props;
  const [minVal, setMinVal] = useState(curMin);
  const [maxVal, setMaxVal] = useState(curMax);
  const [prevMinVal, setPrevMinVal] = useState(curMin);
  const [prevMaxVal, setPrevMaxVal] = useState(curMax);
  const minValRef = useRef(null);
  const maxValRef = useRef(null);
  const range = useRef(null);

  // Convert to percentage
  const getPercent = useCallback(
    (value) => Math.round(((value - min) / (max - min)) * 100),
    [min, max],
  );

  // Set width of the range to decrease from the left side
  useEffect(() => {
    if (maxValRef.current) {
      const minPercent = getPercent(minVal);
      const maxPercent = getPercent(+maxValRef.current.value);

      if (range.current) {
        range.current.style.left = `${minPercent}%`;
        range.current.style.width = `${maxPercent - minPercent}%`;
      }
    }
  }, [minVal, getPercent]);

  // Set width of the range to decrease from the right side
  useEffect(() => {
    if (minValRef.current) {
      const minPercent = getPercent(+minValRef.current.value);
      const maxPercent = getPercent(maxVal);

      if (range.current) {
        range.current.style.width = `${maxPercent - minPercent}%`;
      }
    }
  }, [maxVal, getPercent]);

  const applyTimeRange = () => {
    onChange(id, { min: minVal, max: maxVal });
    setPrevMinVal(minVal);
    setPrevMaxVal(maxVal);
  };

  return (
    <FormFieldWrapper {...props} columns={2}>
      <div className="range-slider-container">
        <input
          name={`field-${id}-min`}
          type="range"
          min={min}
          max={max}
          value={minVal}
          ref={minValRef}
          onChange={(event) => {
            const value = Math.min(+event.target.value, maxVal - 1);
            setMinVal(value);
            event.target.value = value.toString();
          }}
          className={classnames('thumb thumb-min', {
            'thumb-zindex-5': minVal > max - 100,
          })}
        />
        <input
          name={`field-${id}-max`}
          type="range"
          min={min}
          max={max}
          value={maxVal}
          ref={maxValRef}
          onChange={(event) => {
            const value = Math.max(+event.target.value, minVal + 1);
            setMaxVal(value);
            event.target.value = value.toString();
          }}
          className="thumb thumb-max"
        />
        <div className="range-slider">
          <div className="range-slider-track" />
          <div ref={range} className="range-slider-range" />
          <div className="range-slider-left-value">
            {moment.duration(minVal, 'seconds').hours()}:
            {moment.duration(minVal, 'seconds').minutes()}:
            {moment.duration(minVal, 'seconds').seconds()}
          </div>
          <div className="range-slider-right-value">
            {moment.duration(maxVal, 'seconds').hours()}:
            {moment.duration(maxVal, 'seconds').minutes()}:
            {moment.duration(maxVal, 'seconds').seconds()}
          </div>
        </div>
      </div>
      <Segment
        className={
          prevMinVal !== minVal || prevMaxVal !== maxVal ? '' : 'disabled'
        }
        textAlign="center"
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          cursor: 'pointer',
          fontSize: '0.9em',
          height: '46px',
        }}
        onClick={applyTimeRange}
      >
        <FormattedMessage id="Trim" defaultMessage="Trim" />
        <i style={{ marginLeft: '3px', position: 'relative', top: '2px' }}>
          <Icon name={cutSVG} size="25" title="Trim" />
        </i>
      </Segment>
    </FormFieldWrapper>
  );
};

export default injectIntl(RangeSliderWidget);
