import React, { useEffect, useState } from 'react';
import { Loader, Progress, Container, Header } from 'semantic-ui-react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { EPICS_PATH_CONTINUE } from 'volto-epics-addon/constants';
import { TASK_UPLOAD_WAITFORJOBS_VIEW } from '@package/constants';
import { useTaskPoll } from 'volto-epics-addon/helpers';
import UploadStepsContainer from '@package/components/theme/FileUpload/UploadStepsContainer';
import errorIcon from './icons/error.svg';
import { Icon } from '@plone/volto/components';
import config from '@plone/volto/registry';

const Page = () => {
  const history = useHistory();
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [transcodingProgress, setTranscodingProgress] = useState(null);
  const [errorCode, setErrorCode] = useState(undefined);
  const task = useTaskPoll(1000, TASK_UPLOAD_WAITFORJOBS_VIEW);
  const token = useSelector((state) => state.userSession.token);
  const helpLink = `${config.settings.publicURL}/help`;

  useEffect(() => {
    setIsAuthenticated(!!token);
    setIsVisible(true);
  }, [token]);

  useEffect(() => {
    var duration = parseFloat(task?.variables?.duration);
    var progress = task?.variables?.transcodingProgress;
    setErrorCode(task?.variables?.errorCode);
    // Select correct transcoding to follow..
    var currentTime;
    if (progress && '360p' in progress) {
      currentTime = parseFloat(progress['360p']['current_time']);
    } else if (progress && 'Audio' in progress) {
      currentTime = parseFloat(progress['Audio']['current_time']);
    }

    // Update progress bar
    if (duration && currentTime) {
      var percentage = parseInt(
        Math.min(100, Math.max(0, currentTime / duration)) * 100,
      );
      setTranscodingProgress(percentage);
    }

    if (task?.completed === true) {
      history.push(EPICS_PATH_CONTINUE);
    }
  }, [task, history]);

  return isVisible ? (
    isAuthenticated ? (
      <Container className="waitforjobs-view-container">
        <UploadStepsContainer currentStep={2} totalSteps={4} />
        {errorCode === undefined ? (
          <>
            <Header>
              {' '}
              Waiting for the initial processing of the uploaded file to finish.{' '}
            </Header>
            {transcodingProgress && transcodingProgress < 100 ? (
              <>
                <p> Transcoding a preview profile.. </p>
                <Progress percent={transcodingProgress} indicating />
              </>
            ) : (
              <>
                <p> Loading.. </p>
                <Loader active inline />
              </>
            )}
          </>
        ) : (
          <div style={{}}>
            <Header
              style={{
                justifyContent: 'center',
                marginTop: '100px',
              }}
            >
              <Icon name={errorIcon} size="48px" style={{ width: '100%' }} />
              <p>Unexpected Error</p>
            </Header>
            <p style={{ textAlign: 'center' }}>
              Unfortunately, we encountered an error while processing your file.
              For more information please read{' '}
              <a href={helpLink} target="_blank" rel="noreferrer">
                help
              </a>
            </p>
          </div>
        )}
      </Container>
    ) : (
      <Container className="waitforjobs-view-container">
        <Header className="ui header"> You must log in first. </Header>
      </Container>
    )
  ) : (
    <Container className="waitforjobs-view-container"></Container>
  );
};

export default Page;
