/**
 * Querystring actions.
 * @module actions/scopedQuerystring/scopedQuerystring
 */

import { GET_SCOPED_QUERYSTRING } from '@package/constants/ScopedQuerystringActionTypes';

/**
 * Get querystring within content path.
 * @function getScopedQuerystring
 * @param {string} content_path Content path.
 * @returns {Object} Get scopedQuerystring action.
 */
export function getQuerystring(content_path) {
  return {
    type: GET_SCOPED_QUERYSTRING,
    request: {
      op: 'get',
      path: content_path + '/@querystring',
    },
  };
}
