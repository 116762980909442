/**
 * Root reducer.
 * @module reducers/root
 */

import defaultReducers from '@plone/volto/reducers';
import subtitles from '@package/reducers/subtitles/subtitles';
import move from '@package/reducers/move/move';
import scopedQuerystring from '@package/reducers/scopedQuerystring/scopedQuerystring';

/**
 * Root reducer.
 * @function
 * @param {Object} state Current state.
 * @param {Object} action Action to be handled.
 * @returns {Object} New state.
 */
const reducers = {
  ...defaultReducers,
  subtitles,
  move,
  scopedQuerystring,
};

export default reducers;
