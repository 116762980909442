/**
 * Body video block.
 * @module components/manage/Blocks/VideoJS/Body
 */

import React from 'react';
import { Message } from 'semantic-ui-react';
import cx from 'classnames';

import VideoJS from '../../../../components/manage/VideoJS/VideoJS';
import SubtitlesEditor from './SubtitlesEditor';

/**
 * Body videoJS block class.
 * @class Body
 * @extends Component
 */
const Body = (props) => {
  const { block, data, onChangeBlock, keyValue, editMode } = props;
  const [playerInstance, setPlayerInstance] = React.useState(null);
  const [withSubtitles, setWithSubtitles] = React.useState(false);
  const [playerError, setPlayerError] = React.useState(null);

  const setVideoDuration = (player) => {
    // Set total duration once, because after offset plugin is applied duration will be based on new values.
    editMode &&
      !data.options?.duration &&
      onChangeBlock(block, {
        ...data,
        options: {
          ...data.options,
          duration: player.duration(),
        },
      });
  };

  return (
    <>
      {!playerError ? (
        <>
          <div
            className={cx('video-inner', {
              'full-width': data.align === 'full',
            })}
          >
            <div>
              <VideoJS
                key={keyValue}
                selected={props.selected}
                options={data.options}
                parentUid={props.properties?.UID}
                parentType={props.properties?.['@type']}
                parentItems={props.properties?.items}
                onReady={(player, withSubtitles) => {
                  setPlayerInstance(player);
                  setVideoDuration(player);
                  setWithSubtitles(withSubtitles);
                }}
                onError={(player) => {
                  setPlayerError(player.error());
                }}
              />
            </div>
          </div>
          {withSubtitles && editMode && (
            <SubtitlesEditor
              playerInstance={playerInstance}
              parentUid={props.properties.UID}
            />
          )}
        </>
      ) : (
        <div className="invalidVideoFormat">
          <Message>
            <center>{playerError.message}</center>
          </Message>
        </div>
      )}
    </>
  );
};

export default Body;
