import React, { useEffect, useState } from 'react';
import { Accordion, Segment } from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';
import { Icon } from '@plone/volto/components';
import { omit } from 'lodash';
import { toast } from 'react-toastify';
import request from 'superagent';
import rightSVG from '@plone/volto/icons/right-key.svg';
import downSVG from '@plone/volto/icons/down-key.svg';
import AnimateHeight from 'react-animate-height';
import addSVG from '@plone/volto/icons/circle-plus.svg';
import clearSVG from '@plone/volto/icons/clear.svg';
import ObjectBrowserWidget from '@plone/volto/components/manage/Widgets/ObjectBrowserWidget';
import { getContent } from '@plone/volto/actions';
import { useDispatch, useSelector } from 'react-redux';
import config from '@plone/volto/registry';
import { MEDIA_PAGE_TRANSCRIPTION_ROUTE } from '@package/constants';
import { LANGUAGES_LIST } from '@package/constants/ISO639-1';
import { ACCEPTED_LANGUAGE_CODES } from '@package/constants/whisperxAcceptedLanguages';
import { SelectWidget } from '@plone/volto/components';
import { useUIDVariablesPoll } from 'volto-epics-addon/helpers';
import { Progress } from 'semantic-ui-react';
import { flattenToAppURL } from '@plone/volto/helpers';
import CheckboxWidget from '@plone/volto/components/manage/Widgets/CheckboxWidget';
import { Api } from '@plone/volto/helpers';

const Subtitles = (props) => {
  const {
    block,
    data,
    currentActiveFieldset,
    handleCurrentActiveFieldset,
    refreshPlayer,
    onChangeBlock,
    refreshSidebar,
    disabled,
    properties,
  } = props;
  const dispatch = useDispatch();
  const subtitlesLanguageProperty = config.settings.subtitlesLanguageProperty;
  const [transcription, setTranscription] = useState({
    diarization: false,
    language: '',
  });
  const [progress, setProgress] = useState(null);

  const { token } = useSelector((state) => state.userSession);
  const api = new Api();

  // Note: 'disabled' indicates we are in a mediapagevideojs block
  const variables = useUIDVariablesPoll(
    7000,
    properties['UID'],
    ['transcriptionProgress'],
    disabled,
  );

  useEffect(() => {
    if (variables !== null) {
      // There is progress information from camunda
      const flattened = Object.assign([], ...Object.values(variables));
      const transcriptionProgress = JSON.parse(
        flattened['transcriptionProgress'] ?? '{}',
      );

      const newProgress = {};
      const transcriptionProgressParams = Object.keys(transcriptionProgress);
      !transcriptionProgressParams.includes('complete') &&
        transcriptionProgressParams.forEach((key) => {
          newProgress[key] = {
            ...newProgress[key],
            ...transcriptionProgress[key],
          };
        });

      if (Object.keys(newProgress).length !== 0) {
        setProgress(newProgress);
      }
    } else if (progress !== null) {
      // If progress != null and variables == null,
      // there should have been ongoing transcription
      // that is now inferred to have ended.
      setProgress(null);
      setTranscription({
        diarization: false,
        language: '',
      });
      // Refresh content and update player's subtitles
      (async () => {
        const content = await dispatch(
          getContent(
            flattenToAppURL(properties['@id']),
            null,
            'compare_to',
            null,
          ),
        );
        const items = content.items;
        if (items.length) {
          const subtitles = [];
          for (const item of items) {
            if (item['@type'] === 'subtitle') {
              // Only take subtitles marked as completed
              const workflow = await api.get(
                flattenToAppURL(item['@id']) + '/@workflow',
                {
                  headers: {
                    Authorization: `Bearer ${token}`,
                  },
                },
              );
              const state =
                workflow?.['chain']?.['media_output_workflow']?.['state']?.[
                  'id'
                ];
              if (state && state !== 'completed') {
                continue;
              }
              subtitles.push([
                {
                  '@id': flattenToAppURL(item['@id']),
                  title: item['title'],
                  language: item['title'],
                },
              ]);
            }
          }

          if (subtitles.length) {
            onChangeBlock(block, {
              ...data,
              options: {
                ...data.options,
                subtitles: subtitles,
              },
            });
            refreshPlayer();
          }
        }
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [variables, dispatch]);

  const generateSubtitles = async () => {
    // Check if there's audio transcoding available
    const audioItems = [];
    await dispatch(
      getContent(flattenToAppURL(properties['@id']), null, 'compare_to', null),
    )
      .then((content) => {
        content.items.forEach((item) => {
          if (item['@type'] === 'transcoding' && item['title'] === 'Audio') {
            audioItems.push(item);
          }
        });
      })
      // eslint-disable-next-line no-console
      .catch((error) => console.log(error));

    // If no audio transcoding is found, inform the user and quit.
    if (audioItems.length === 0) {
      toast.error(
        'Subtitle generation failed to start because the media lacks audio content.',
      );
      return;
    }

    // Start subtitle generation
    try {
      const mediaPageUid = props['properties']['UID'];
      await request
        .post(
          `${config.settings.publicPath}/${MEDIA_PAGE_TRANSCRIPTION_ROUTE}/${mediaPageUid}`,
        )
        .send({
          operationsFinal: {
            subtitles: transcription,
            trim: data['initialTrim'],
          },
        });
      setProgress({
        transcription: {
          percentage: '0',
        },
      });
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  };

  const addSubtitles = () => {
    if (data.options.subtitles?.length) {
      onChangeBlock(block, {
        ...data,
        options: {
          ...data.options,
          subtitles: [...data.options.subtitles, []],
        },
      });
    } else {
      onChangeBlock(block, {
        ...data,
        options: {
          ...data.options,
          subtitles: [[]],
        },
      });
    }
  };

  const deleteSubtitles = (index) => {
    if (index === 0 && data.options.subtitles.length === 1) {
      onChangeBlock(block, {
        ...data,
        options: {
          ...omit(data.options, ['subtitles']),
        },
      });
    } else {
      onChangeBlock(block, {
        ...data,
        options: {
          ...data.options,
          subtitles: [
            ...data.options.subtitles.slice(0, index),
            ...data.options.subtitles.slice(index + 1),
          ],
        },
      });
    }

    refreshSidebar();
    refreshPlayer();
  };

  return (
    <div
      key="blockform-fieldset-subtitles"
      id="blockform-fieldset-subtitles"
      className="videojs-sidebar-subtitles"
    >
      <Accordion.Title
        active={currentActiveFieldset === 1}
        index={1}
        onClick={handleCurrentActiveFieldset}
      >
        <>Subtitles</>
        {currentActiveFieldset === 1 ? (
          <Icon name={downSVG} size="20px" />
        ) : (
          <Icon name={rightSVG} size="20px" />
        )}
      </Accordion.Title>
      <Accordion.Content active={currentActiveFieldset === 1}>
        <AnimateHeight
          animateOpacity
          duration={500}
          height={currentActiveFieldset === 1 ? 'auto' : 2}
        >
          <p
            className="help"
            style={{
              padding: '1em',
            }}
          >
            {`The source supposed to be an object of subtitle content type.`}
          </p>
          {data.options?.subtitles?.map((source, index) => {
            return (
              <Segment key={index}>
                <div
                  style={{
                    display: 'flex',
                    backgroundColor: 'aliceblue',
                    alignItems: 'center',
                  }}
                >
                  {data.options?.subtitles?.[index]?.[0]?.language && (
                    <span>
                      {data.options?.subtitles?.[index]?.[0]?.language}
                    </span>
                  )}
                  {!disabled && (
                    <button
                      style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        fontSize: '0.9em',
                        width: '100%',
                      }}
                      onClick={() => deleteSubtitles(index)}
                    >
                      <i
                        style={{
                          position: 'relative',
                          top: '2px',
                          cursor: 'pointer',
                        }}
                      >
                        <Icon
                          name={clearSVG}
                          title="Delete"
                          size="2em"
                          color="#e40166"
                        />
                      </i>
                    </button>
                  )}
                </div>
                {!disabled && (
                  <ObjectBrowserWidget
                    key={`videojs-subtitles-widget-` + index}
                    id={`videojs-subtitles-widget-` + index}
                    title={`Source`}
                    mode="link" // link, image, multiple
                    selectableTypes={['subtitle']}
                    onChange={(id, value) => {
                      if (value.length && value[0]['@id']) {
                        const subtitlesContentPath = value[0]['@id'];
                        dispatch(
                          getContent(
                            subtitlesContentPath,
                            null,
                            'compare_to',
                            null,
                          ),
                        )
                          .then((content) => {
                            onChangeBlock(block, {
                              ...data,
                              options: {
                                ...data.options,
                                subtitles: [
                                  ...data.options.subtitles.slice(0, index),
                                  [
                                    {
                                      ...value[0],
                                      language:
                                        content?.[subtitlesLanguageProperty],
                                    },
                                  ],
                                  ...data.options.subtitles.slice(index + 1),
                                ],
                              },
                            });
                            refreshPlayer();
                          })
                          // eslint-disable-next-line no-console
                          .catch((error) => console.log(error));
                      } else {
                        onChangeBlock(block, {
                          ...data,
                          options: {
                            ...data.options,
                            subtitles: [
                              ...data.options.subtitles.slice(0, index),
                              value,
                              ...data.options.subtitles.slice(index + 1),
                            ],
                          },
                        });
                        refreshPlayer();
                      }
                    }}
                    block={block}
                    value={data.options?.subtitles?.[index] || []}
                  />
                )}
              </Segment>
            );
          })}
          <SelectWidget
            disabled={progress && Object.keys(progress).length !== 0}
            className="transcription-language-select"
            id="transcription-language"
            title="Speech language"
            description="By choosing a language you improve accuracy and pricision of final result"
            choices={Object.keys(LANGUAGES_LIST)
              .filter((key) => ACCEPTED_LANGUAGE_CODES.includes(key))
              .map((key) => [key, LANGUAGES_LIST[key]['name']])}
            onChange={(id, value) =>
              setTranscription((prevState) => ({
                ...prevState,
                language: value,
              }))
            }
            value={transcription['language'] || ''}
          />
          <CheckboxWidget
            isDisabled={progress && Object.keys(progress).length !== 0}
            id="speaker_diarization"
            key="speaker_diarization"
            title="Speaker diarization"
            value={transcription.diarization}
            onChange={(id, value) => {
              setTranscription((prevState) => ({
                ...prevState,
                diarization: value,
              }));
            }}
            block={block}
            description="Label speakers"
          />
          <CheckboxWidget
            isDisabled={progress && Object.keys(progress).length !== 0}
            id="subtitles_translation"
            key="subtitles_translation"
            title="Translate to English"
            value={transcription.translate}
            onChange={(id, value) => {
              setTranscription((prevState) => ({
                ...prevState,
                translate: value,
              }));
            }}
            block={block}
            description="Translate subtitles to English"
          />
          {progress ? (
            <>
              {progress?.['transcribe'] && (
                <div
                  className="mediapage-transcription-progress-indicator"
                  style={{
                    width: '90%',
                    margin: '0 auto',
                  }}
                >
                  <div key={'transcribe'} style={{ fontSize: '0.8rem' }}>
                    {progress['transcribe']['detectedLanguage'] ? (
                      <div>
                        Subtitles language:{' '}
                        {progress['transcribe']['detectedLanguage']}
                      </div>
                    ) : (
                      <div>Subtitle processing ...</div>
                    )}
                    <Progress
                      percent={progress['transcribe']['percentage']}
                      indicating
                      progress
                    />
                  </div>
                </div>
              )}
              {progress?.['translate'] && (
                <div
                  className="mediapage-translation-progress-indicator"
                  style={{
                    width: '90%',
                    margin: '0 auto',
                  }}
                >
                  <div key={'translate'} style={{ fontSize: '0.8rem' }}>
                    <div>Subtitles translation to English ...</div>
                    <Progress
                      percent={progress['translate']['percentage']}
                      indicating
                      progress
                    />
                  </div>
                </div>
              )}
            </>
          ) : (
            <Segment
              textAlign="center"
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                cursor: 'pointer',
                fontSize: '0.9em',
                height: '46px',
              }}
              onClick={
                !disabled ? addSubtitles : async () => await generateSubtitles()
              }
            >
              {!disabled ? (
                <FormattedMessage
                  id="Add subtitles"
                  defaultMessage="Add subtitles"
                />
              ) : (
                <FormattedMessage
                  id="Generate subtitles"
                  defaultMessage="Generate subtitles"
                />
              )}
              <i
                style={{ marginLeft: '3px', position: 'relative', top: '3px' }}
              >
                <Icon name={addSVG} size="32px" title="Add subtitles" />
              </i>
            </Segment>
          )}
        </AnimateHeight>
      </Accordion.Content>
    </div>
  );
};

export default Subtitles;
