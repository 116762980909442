import React from 'react';
import { Segment } from 'semantic-ui-react';
import AlignWidget from '@plone/volto/components/manage/Widgets/AlignWidget';
import FileWidget from '@plone/volto/components/manage/Widgets/FileWidget';

const BlockConfig = (props) => {
  const { block, data, onChangeBlock, refreshPlayer } = props;

  return (
    <div id="blockform-fieldset-default">
      <Segment className="form attached">
        <AlignWidget
          id="align"
          value={data.align}
          onChange={(id, value) => {
            onChangeBlock(block, {
              ...data,
              [id]: value,
            });
          }}
          key="align"
          block={block}
          title="Alignment"
        />
        <FileWidget
          id="poster"
          value={data.poster}
          onChange={(id, value) => {
            if (value) {
              onChangeBlock(block, {
                ...data,
                poster: value,
                options: {
                  ...data.options,
                  // Data url
                  poster: `data:${value['content-type']};${value.encoding},${value.data}`,
                },
              });
            } else {
              delete data.poster;
              delete data.options['poster'];
              onChangeBlock(block, {
                ...data,
                options: {
                  ...data.options,
                },
              });
            }
            refreshPlayer();
          }}
          key="poster"
          description="A URL to an image that displays before the video begins playing. This is often a frame of the video or a custom title screen. As soon as the user hits 'play' the image will go away."
          block={block}
          title="Poster"
        />
      </Segment>
    </div>
  );
};

export default BlockConfig;
