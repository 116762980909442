import { useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { Button } from 'semantic-ui-react';
import Disclaimer from './Disclaimer';

const messages = defineMessages({
  acknowledgementText: {
    id: 'attachmentDownloadAcknowledgementText',
    defaultMessage:
      'I have understood the above and agree to handle the data with care.',
  },
});

const AttachmentDownload = ({ url }) => {
  const [isAcknowledged, setIsAcknowledged] = useState(false);
  const intl = useIntl();

  const downloadButtonHandler = async (event) => {
    event.preventDefault();

    if (!isAcknowledged) {
      return;
    }
    window.location.href = url;
  };

  return (
    <div className="attachment-download-container">
      <h2>Export</h2>
      <Disclaimer />

      <label style={{ display: 'block', marginTop: '20px' }}>
        <input
          type="checkbox"
          style={{ marginRight: '10px' }}
          checked={isAcknowledged}
          onChange={() => setIsAcknowledged(!isAcknowledged)}
        />
        {intl.formatMessage(messages.acknowledgementText)}
      </label>
      <Button
        onClick={downloadButtonHandler}
        disabled={!isAcknowledged}
        style={{ marginTop: '10px' }}
      >
        Download
      </Button>
    </div>
  );
};

export default AttachmentDownload;
