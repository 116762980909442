import { UPDATE_SUBTITLES } from '@package/constants/SubtitlesActionTypes';

/**
 * Get request key
 * @function getRequestKey
 * @param {string} actionType Action type.
 * @returns {string} Request key.
 */
function getRequestKey(actionType) {
  return actionType.split('_')[0].toLowerCase();
}

const initialState = {
  update: {
    loaded: false,
    loading: false,
    error: null,
  },
};

export default function subtitles(state = initialState, action = {}) {
  switch (action.type) {
    case `${UPDATE_SUBTITLES}_PENDING`:
      return {
        ...state,
        [getRequestKey(action.type)]: {
          loading: true,
          loaded: false,
          error: null,
        },
      };
    case `${UPDATE_SUBTITLES}_SUCCESS`:
      return {
        ...state,
        [getRequestKey(action.type)]: {
          loading: false,
          loaded: true,
          error: null,
        },
      };
    case `${UPDATE_SUBTITLES}_FAIL`:
      return {
        ...state,
        [getRequestKey(action.type)]: {
          loading: false,
          loaded: false,
          error: action.error,
        },
      };
    default:
      return state;
  }
}
