export const MEDIA_FILE_AUDIO_TYPES = [
  'audio/mpeg',
  'audio/mp4',
  'audio/wav',
  'audio/ogg',
  'audio/x-ms-wma',
  'audio/aac',
  'audio/flac',
  'audio/vnd.rn-realaudio',
  'audio/x-ms-wax',
  'audio/x-ms-wmx',
  'audio/x-ms-wm',
  'audio/vnd.wave',
  'audio/aiff',
  'audio/x-aiff',
  'audio/x-pn-realaudio',
  'audio/x-pn-realaudio-plugin',
  'audio/basic',
  'audio/x-wav',
  'audio/x-mp3',
  'audio/webm',
  'audio/midi',
  'audio/x-midi',
  'audio/x-aac',
  'audio/x-aacp',
  'audio/x-caf',
  'audio/x-flac',
  'audio/x-wavpack',
  'audio/mp3',
  'audio/mpg',
  'audio/3gpp',
  'audio/3gpp2',
  'audio/acc',
  'audio/mpeg3',
  'audio/x-hx-aac-adts',
  'audio/webm',
  'audio/m4a',
  'audio/mpeg4-generic',
  'audio/x-m4a',
];

export const MEDIA_FILE_VIDEO_TYPES = [
  'video/mp4',
  'video/webm',
  'video/ogg',
  'video/avi',
  'video/mpeg',
  'video/quicktime',
  'video/3gpp',
  'video/3gpp2',
  'video/x-msvideo',
  'video/mp2t',
  'video/x-ms-wmv',
  'video/x-flv',
  'video/x-matroska',
  'video/x-m4v',
  'video/x-ms-asf',
  'video/x-ms-avi',
  'video/x-ms-wmv',
  'video/x-mp4',
  'video/x-matroska',
  'video/x-flv',
  'video/x-mpeg',
  'video/x-msvideo',
  'video/x-sgi-movie',
  'video/vnd.vivo',
  'video/vnd.dece.hd',
  'video/vnd.dece.mobile',
  'video/vnd.uvvu.mp4',
  'video/vnd.dece.pd',
  'video/vnd.uvvu.mp4',
  'video/vnd.dece.sd',
  'video/vnd.avi',
  'video/msvideo',
  'video/x-msvideo',
  'video/x-la-asf',
  'video/x-ivf',
  'video/x-m4v',
];
