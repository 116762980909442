const Disclaimer = () => {
  return (
    <p>
      Please note that you are exporting data that might contain sensitive
      information. Researchvideo has been designed as a safe operating
      environment for handling such data. When exporting, you have to take
      necessary precautions to protect the data. These precautions include
      minimization of handling of personal information (e.g. pseudonymization)
      and choosing suitable, secure JYU storage. Take extra caution if you are
      dealing with special categories of personal information. Please refer to
      the{' '}
      <a href="https://uno.jyu.fi/en/help-centre/security-and-data-privacy/information_security/information-security-guides/dynamic-table-of-processing-confidential-information-1">
        dynamic table to check the needed data security measures in storing the
        files.
      </a>{' '}
      If you need help with this, contact researchsupport-osc@jyu.fi.
    </p>
  );
};

export default Disclaimer;
