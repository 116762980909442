import { VideoJSView, VideoJSEdit } from '@package/components';
import { MediaPageVideoJSView } from '@package/components';
import { ChannelsBlockView, ChannelsBlockEdit } from '@package/components';
import { DownloadsBlockView, DownloadsBlockEdit } from '@package/components';
import { UploadButtonView, UploadButtonEdit } from '@package/components';
import GridCardListing from '@package/components/manage/Blocks/Listing/GridCardListing';
import MediaPageSummaryTemplate from '@package/components/manage/Blocks/Listing/MediaPageSummaryTemplate';

import videoIcon from './icons/videojs.svg';
import heroSVG from '@plone/volto/icons/hero.svg';

import '@plone/volto/config';

import { EPICS_TASK_VIEWS } from 'volto-epics-addon/constants';
import {
  TASK_UPLOAD_PROMPT,
  TASK_UPLOAD_BASICINFO_PROMPT,
  TASK_UPLOAD_WAITFORJOBS_VIEW,
  TASK_INTERMEDIATE_JOBS_VIEW,
  TASK_UPLOAD_DETAILS_PROMPT,
} from '@package/constants';
import { MEDIA_PAGE_WOWZA_ROUTE } from './constants';

import { BodyInjection } from '@package/components';

export default function applyConfig(config) {
  // VideoJS
  config.settings.subtitlesLanguageProperty = 'title';
  config.settings.subtitlesId = 's3_subtitles_id';
  config.settings.subtitlesCueInterval = 20;
  config.settings.remoteTextTrackIdPrefix = 'vjs_track_';
  config.settings.videoJSAllowedOptions = [
    'controls',
    'fluid',
    'height',
    'playbackRates',
    'responsive',
    'sources',
    'poster',
    'audioOnlyMode',
    'autoplay',
    'width',
    'loop',
    'liveui',
    'muted',
    'watermark',
    'subtitles',
  ];

  config.settings.defaultPageSize = 20;
  config.settings.isMultilingual = false;
  config.settings.supportedLanguages = ['fi'];
  config.settings.defaultLanguage = 'fi';
  config.settings.publicPath = '';

  config.settings.nonContentRoutes = [
    ...config.settings.nonContentRoutes,
    '/file-upload',
    EPICS_TASK_VIEWS[TASK_UPLOAD_PROMPT],
    EPICS_TASK_VIEWS[TASK_UPLOAD_BASICINFO_PROMPT],
    EPICS_TASK_VIEWS[TASK_UPLOAD_WAITFORJOBS_VIEW],
    EPICS_TASK_VIEWS[TASK_INTERMEDIATE_JOBS_VIEW],
    EPICS_TASK_VIEWS[TASK_UPLOAD_DETAILS_PROMPT],
    new RegExp(`${config.settings.publicPath}/${MEDIA_PAGE_WOWZA_ROUTE}/.*`),
  ];

  config.blocks.blocksConfig.videojs = {
    id: 'videojs',
    title: 'VideoJS',
    group: 'common',
    icon: videoIcon,
    view: VideoJSView,
    edit: VideoJSEdit,
    restricted: false,
    mostUsed: true,
    sidebarTab: 1,
    security: {
      addPermission: [],
      view: [],
    },
  };

  config.blocks.blocksConfig.mediapagevideojs = {
    id: 'mediapagevideojs',
    title: 'Media Page VideoJS',
    group: 'common',
    icon: videoIcon,
    view: MediaPageVideoJSView,
    edit: VideoJSEdit,
    restricted: false,
    mostUsed: false,
    sidebarTab: 1,
    blockHasOwnFocusManagement: true,
    security: {
      addPermission: [],
      view: [],
    },
  };

  config.blocks.blocksConfig.uploadbutton = {
    id: 'uploadbutton',
    title: 'Upload Button',
    icon: heroSVG,
    group: 'common',
    view: UploadButtonView,
    edit: UploadButtonEdit,
    restricted: false,
    mostUsed: false,
    sidebarTab: 1,
  };

  config.blocks.blocksConfig.downloads = {
    id: 'downloads',
    title: 'Downloads',
    icon: heroSVG,
    group: 'media',
    view: DownloadsBlockView,
    edit: DownloadsBlockEdit,
    restricted: false,
    mostUsed: false,
    sidebarTab: 1,
  };

  config.blocks.blocksConfig.channels = {
    id: 'channels',
    title: 'Channels',
    icon: heroSVG,
    group: 'common',
    view: ChannelsBlockView,
    edit: ChannelsBlockEdit,
    restricted: false,
    mostUsed: true,
    sidebarTab: 1,
  };

  config.blocks.blocksConfig.gridBlock.allowedBlocks = [
    'image',
    'listing',
    'slate',
    'teaser',
    'videojs',
  ];

  config.blocks.blocksConfig.listing.variations = [
    {
      id: 'mediapagesummary',
      title: 'MediaPageSummary',
      template: MediaPageSummaryTemplate,
      fullobjects: true,
    },
    {
      id: 'gridcardlisting',
      title: 'Grid Card Listing',
      template: GridCardListing,
      fullobjects: true,
    },
    ...config.blocks.blocksConfig.listing.variations,
  ];

  // Extend listing block with a hide property
  config.blocks.blocksConfig.listing.schemaEnhancer = ({
    schema,
    formData,
    intl,
  }) => {
    schema.properties.hideIfEmpty = {
      title: 'Hide if empty',
      type: 'boolean',
      default: false,
      description:
        'If checked, the block will not render when there are no items to display.',
    };
    schema.fieldsets[0].fields.push('hideIfEmpty');

    return schema;
  };

  // Hide some blocks from UI
  const hiddenBlocks = [
    'videojs',
    'mediapagevideojs',
    'channels',
    'downloads',
    'uploadbutton',
    'video',
  ];
  Object.keys(config.blocks.blocksConfig).forEach((blockId, idx) => {
    const blockConfig = config.blocks.blocksConfig[blockId];
    const originalRestricted = blockConfig.restricted;
    blockConfig.restricted = (props) => {
      if (hiddenBlocks.includes(blockId)) {
        return true;
      }
      return originalRestricted;
    };
  });

  config.settings.appExtras = [
    ...config.settings.appExtras,
    {
      match: '',
      component: BodyInjection,
    },
  ];

  if (__SERVER__) {
    const myMiddleware = require('@package/middleware');
    config.settings.expressMiddleware.push(myMiddleware.default(config));
  }

  return config;
}
