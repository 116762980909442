/**
 * Logo component.
 * @module components/theme/Logo/Logo
 */

import { defineMessages, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import config from '@plone/volto/registry';
import { UniversalLink } from '@plone/volto/components';
import './styles.less';

import LogoSVG from './Logo.svg'; // Update the import
import LogoIcon from './Logo-icon.svg';

const messages = defineMessages({
  site: {
    id: 'Site',
    defaultMessage: 'Site',
  },
  plonesite: {
    id: 'Plone Site',
    defaultMessage: 'Plone Site',
  },
});

/**
 * Logo component class.
 * @function Logo
 * @param {Object} intl Intl object
 * @returns {string} Markup of the component.
 */

const Logo = () => {
  const { settings } = config;
  const lang = useSelector((state) => state.intl.locale);
  const intl = useIntl();

  return (
    <UniversalLink
      href={settings.isMultilingual ? `/${lang}` : '/'}
      title={intl.formatMessage(messages.site)}
      className="Logo-link"
    >
      <img
        className="Site-logo"
        src={LogoSVG}
        alt="researchVideo"
        style={{ width: '347.77px', height: '63.7px' }}
      />
      <img className="Site-logo-icon" src={LogoIcon} alt="researchVideo" />
    </UniversalLink>
  );
};

export default Logo;
