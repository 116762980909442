/**
 * Anontools component.
 * @module components/theme/Anontools/Anontools
 */

import React from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { Menu } from 'semantic-ui-react';
import { FormattedMessage, defineMessages, injectIntl } from 'react-intl';
import { getVar } from '@package/helpers/utils';

const messages = defineMessages({
  login: {
    id: 'Log in',
    defaultMessage: 'Log in',
  },
});

const Anontools = ({ intl }) => {
  const token = useSelector((state) => state.userSession.token);
  const location = useLocation();

  const cameFromPrefix = getVar('RAZZLE_CAME_FROM_PREFIX', '');

  var loginUrl = getVar('RAZZLE_LOGIN_URL', '/login');
  if (cameFromPrefix && location.pathname) {
    const pathName = location.pathname.endsWith('.html')
      ? location.pathname.split('/').slice(0, -1).join('/') || '/'
      : location.pathname;

    loginUrl = loginUrl + '?came_from=' + cameFromPrefix + pathName;
  }

  return (
    !token && (
      <Menu pointing secondary floated="right">
        <Menu.Item>
          <a aria-label={intl.formatMessage(messages.login)} href={loginUrl}>
            <FormattedMessage id="Log in" defaultMessage="Log in" />
          </a>
        </Menu.Item>
      </Menu>
    )
  );
};

export default injectIntl(Anontools);
