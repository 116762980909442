import React, { useEffect, useState } from 'react';
import { Container, Header } from 'semantic-ui-react';
import { useSelector } from 'react-redux';
import { startEpic } from 'volto-epics-addon/helpers';
import { EPIC_FILE_UPLOAD } from '@package/constants';

const Page = () => {
  const token = useSelector((state) => state.userSession.token);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isVisible, setIsVisible] = useState(false);

  // Start the epic
  useEffect(() => {
    setIsAuthenticated(!!token);
    setIsVisible(true);

    if (!!token) {
      let destinationPath = '/uploads';

      startEpic(EPIC_FILE_UPLOAD, {
        destinationPath: destinationPath,
      });
    }
  }, [token]);

  return isVisible ? (
    isAuthenticated ? (
      <Container className="file-upload-start-container">
        <Header> Starting upload process.. </Header>
      </Container>
    ) : (
      <Container className="file-upload-start-container">
        <Header> You must log in first. </Header>
      </Container>
    )
  ) : (
    <Container className="file-upload-start-container"></Container>
  );
};

export default Page;
