import { MOVE_MEDIA_PAGE } from '@package/constants/MoveActionTypes';
import { flattenToAppURL } from '@plone/volto/helpers/Url/Url';

/**
 * Get request key
 * @function getRequestKey
 * @param {string} actionType Action type.
 * @returns {string} Request key.
 */
function getRequestKey(actionType) {
  return actionType.split('_')[0].toLowerCase();
}

const initialState = {
  move: {
    result: null,
    loaded: false,
    loading: false,
    error: null,
  },
};

export default function move(state = initialState, action = {}) {
  switch (action.type) {
    case `${MOVE_MEDIA_PAGE}_PENDING`:
      return {
        ...state,
        [getRequestKey(action.type)]: {
          result: null,
          loading: true,
          loaded: false,
          error: null,
        },
      };
    case `${MOVE_MEDIA_PAGE}_SUCCESS`:
      return {
        ...state,
        [getRequestKey(action.type)]: {
          result: flattenToAppURL(action.result[0].target),
          loading: false,
          loaded: true,
          error: null,
        },
      };
    case `${MOVE_MEDIA_PAGE}_FAIL`:
      return {
        ...state,
        [getRequestKey(action.type)]: {
          result: null,
          loading: false,
          loaded: false,
          error: action.error,
        },
      };
    default:
      return state;
  }
}
