import { MOVE_MEDIA_PAGE } from '@package/constants/MoveActionTypes';
import { flattenToAppURL } from '@plone/volto/helpers/Url/Url';
import config from '@plone/volto/registry';

export function moveMediaPage(sourceUid, targetId) {
  return {
    type: MOVE_MEDIA_PAGE,
    request: {
      op: 'post',
      path: `${config.settings.apiPath}/++api++${flattenToAppURL(
        targetId,
      )}/@move-media_page`,
      data: { source: sourceUid },
      headers: {},
    },
  };
}
