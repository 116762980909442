import React from 'react';
import { Tab } from 'semantic-ui-react';
import CheckboxWidget from '@plone/volto/components/manage/Widgets/CheckboxWidget';
import SelectWidget from '@plone/volto/components/manage/Widgets/SelectWidget';
import NumberWidget from '@plone/volto/components/manage/Widgets/NumberWidget';

const Options = (props) => {
  const { block, data, onChangeBlock, refreshPlayer } = props;

  return (
    <Tab.Pane>
      <CheckboxWidget
        id="audioOnlyMode"
        value={data.options?.audioOnlyMode}
        onChange={(id, value) => {
          onChangeBlock(block, {
            ...data,
            options: {
              ...data.options,
              [id]: value,
            },
          });
          refreshPlayer();
        }}
        key="audioOnlyMode"
        description="If set to true, it asynchronously hides all player components except the control bar, as well as any specific controls that are needed only for video."
        block={block}
        title="audioOnlyMode"
      />
      <CheckboxWidget
        id="autoplay"
        value={data.options?.autoplay}
        onChange={(id, value) => {
          onChangeBlock(block, {
            ...data,
            options: {
              ...data.options,
              [id]: value,
            },
          });
          refreshPlayer();
        }}
        key="autoplay"
        description="NOTE: At this point, the autoplay attribute and option are NOT a guarantee that your video will autoplay."
        block={block}
        title="autoplay"
      />
      <CheckboxWidget
        id="controls"
        value={data.options?.controls}
        onChange={(id, value) => {
          onChangeBlock(block, {
            ...data,
            options: {
              ...data.options,
              [id]: value,
            },
          });
          refreshPlayer();
        }}
        key="controls"
        description="Determines whether or not the player has controls that the user can interact with. Without controls the only way to start the video playing is with the autoplay attribute or through the Player API."
        block={block}
        title="controls"
      />
      <NumberWidget
        id="width"
        value={data.options?.width}
        onChange={(id, value) => {
          onChangeBlock(block, {
            ...data,
            options: {
              ...data.options,
              [id]: value,
            },
          });
          refreshPlayer();
        }}
        key="width"
        description="Sets the display width of the video player in pixels."
        block={block}
        title="width"
      />
      <NumberWidget
        id="height"
        value={data.options?.height}
        onChange={(id, value) => {
          onChangeBlock(block, {
            ...data,
            options: {
              ...data.options,
              [id]: value,
            },
          });
          refreshPlayer();
        }}
        key="height"
        description="Sets the display height of the video player in pixels."
        block={block}
        title="height"
      />
      <CheckboxWidget
        id="loop"
        value={data.options?.loop}
        onChange={(id, value) => {
          onChangeBlock(block, {
            ...data,
            options: {
              ...data.options,
              [id]: value,
            },
          });
          refreshPlayer();
        }}
        key="loop"
        description="Causes the video to start over as soon as it ends."
        block={block}
        title="loop"
      />
      <CheckboxWidget
        id="responsive"
        value={data.options?.responsive}
        onChange={(id, value) => {
          onChangeBlock(block, {
            ...data,
            options: {
              ...data.options,
              [id]: value,
            },
          });
          refreshPlayer();
        }}
        key="responsive"
        description="Setting this option to true will cause the player to customize itself based on responsive breakpoints (see: breakpoints option). When this option is false (the default), responsive breakpoints will be ignored. Note this is about the responsiveness of the controls within the player, not responsive sizing of the player itself. For that, see fluid."
        block={block}
        title="responsive"
      />
      <CheckboxWidget
        id="fluid"
        value={data.options?.fluid}
        onChange={(id, value) => {
          onChangeBlock(block, {
            ...data,
            options: {
              ...data.options,
              [id]: value,
            },
          });
          refreshPlayer();
        }}
        key="fluid"
        description="When true, the Video.js player will have a fluid size. In other words, it will scale to fit its container at the video's intrinsic aspect ratio, or at a specified aspectRatio."
        block={block}
        title="fluid"
      />
      <CheckboxWidget
        id="liveui"
        value={data.options?.liveui}
        onChange={(id, value) => {
          onChangeBlock(block, {
            ...data,
            options: {
              ...data.options,
              [id]: value,
            },
          });
          refreshPlayer();
        }}
        key="liveui"
        description="Allows the player to use the new live ui that includes:
                        1. A progress bar for seeking within the live window
                        2. A button that can be clicked to seek to the live edge with a circle indicating if you are at the live edge or not.
                        Without this option the progress bar will be hidden and in its place will be text that indicates LIVE playback. There will be no progress control and you will not be able click the text to seek to the live edge. liveui will default to true in a future version!"
        block={block}
        title="liveui"
      />
      <CheckboxWidget
        id="muted"
        value={data.options?.muted}
        onChange={(id, value) => {
          onChangeBlock(block, {
            ...data,
            options: {
              ...data.options,
              [id]: value,
            },
          });
          refreshPlayer();
        }}
        key="muted"
        description="Will silence any audio by default."
        block={block}
        title="muted"
      />
      <SelectWidget
        id="playbackRates"
        value={data.options?.playbackRates}
        onChange={(id, value) => {
          if (value.length) {
            onChangeBlock(block, {
              ...data,
              options: {
                ...data.options,
                [id]: value,
              },
            });
          } else {
            delete data.options[id];
            onChangeBlock(block, {
              ...data,
              options: {
                ...data.options,
              },
            });
          }
          refreshPlayer();
        }}
        choices={[
          [0.5, '0.5'],
          [0.75, '0.75'],
          [1, '1'],
          [1.25, '1.25'],
          [1.5, '1.5'],
          [2, '2'],
        ]}
        noValueOption={false}
        isMulti={true}
        key="playbackRates"
        description="An array of numbers strictly greater than 0, where 1 means regular speed (100%), 0.5 means half-speed (50%), 2 means double-speed (200%), etc. If specified, Video.js displays a control (of class vjs-playback-rate) allowing the user to choose playback speed from among the array of choices. The choices are presented in the specified order from bottom to top."
        block={block}
        title="playbackRates"
      />
    </Tab.Pane>
  );
};

export default Options;
